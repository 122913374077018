.linkGroup {
    padding: 20px 0 8px 24px;
    font-size: 0;
    & > a {
        display: inline-block;
        width: 25%;
        margin-bottom: 13px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
    }
}
