.pageHeaderContent {
    display: flex;
    .avatar {
        flex: 0 1 72px;
        & > span {
            display: block;
            width: 72px;
            height: 72px;
            border-radius: 72px;
        }
    }
    .content {
        position: relative;
        top: 4px;
        flex: 1 1 auto;
        margin-left: 24px;
        line-height: 22px;
        .contentTitle {
            margin-bottom: 12px;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
        }
    }
}

.extraContent {
    zoom: 1;
    &::before,
    &::after {
        display: table;
        content: ' ';
    }
    &::after {
        clear: both;
        height: 0;
        font-size: 0;
        visibility: hidden;
    }
    float: right;
    white-space: nowrap;
    .statItem {
        position: relative;
        display: inline-block;
        padding: 0 32px;
        > p:first-child {
            margin-bottom: 4px;
            color: #999;
            font-size: 14px;
            line-height: 22px;
        }
        > p {
            margin: 0;
            color: rgba(0, 0, 0, 0.45);
            font-size: 30px;
            line-height: 38px;
            > span {
                color: #999;
                font-size: 20px;
            }
        }
        &::after {
            position: absolute;
            top: 8px;
            right: 0;
            width: 1px;
            height: 40px;
            background-color: #e8e8e8;
            content: '';
        }
        &:last-child {
            padding-right: 0;
            &::after {
                display: none;
            }
        }
    }
}

.members {
    a {
        display: block;
        height: 24px;
        margin: 12px 0;
        color: #666;
        transition: all 0.3s;
        .member {
            margin-left: 12px;
            font-size: 14px;
            line-height: 24px;
            vertical-align: top;
        }
        &:hover {
            color: #333;
        }
    }
}

.projectList {
    :global {
        .ant-card-meta-description {
            height: 44px;
            overflow: hidden;
            color: #666;
            line-height: 22px;
        }
    }
    .cardTitle {
        font-size: 0;
        a {
            display: inline-block;
            height: 24px;
            margin-left: 12px;
            color: #333;
            font-size: 14px;
            line-height: 24px;
            vertical-align: top;
            &:hover {
                color: #333;
            }
        }
    }
    .projectGrid {
        width: 33.33%;
    }
    .projectItemContent {
        display: flex;
        height: 20px;
        margin-top: 8px;
        overflow: hidden;
        font-size: 12px;
        line-height: 20px;
        a {
            display: inline-block;
            flex: 1 1 0;
            color: #666;
            &:hover {
                color: #333;
            }
        }
        .datetime {
            flex: 0 0 auto;
            float: right;
            color: #333;
        }
    }
}

.activitiesList {
    padding: 0 24px 8px 24px;
    .username {
        color: #333;
    }
    .event {
        font-weight: normal;
    }
}

.datetime {
    color: #333;
}
