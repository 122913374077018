.globalFooter {
    margin: 48px 0 24px 0;
    padding: 0 16px;
    text-align: center;

    .links {
        margin-bottom: 8px;

        a {
            transition: all 0.3s;

            &:not(:last-child) {
                margin-right: 40px;
            }
        }
    }

    .copyright {
        font-size: 12px;
    }
}