.logo {
    position: relative;
    overflow: hidden;
    background: #002140;
    transition: all 0.3s;
    height: 64px;
    padding-left: 24px;
    line-height: 64px;

    a {
        color: #1890ff;
    }

    svg {
        height: 32px;
        width: 32px;
        display: inline-block;
        vertical-align: middle;
        fill: #1890ff;
    }

    img {
        display: inline-block;
        height: 32px;
        vertical-align: middle;
    }

    h1 {
        display: inline-block;
        margin: 0 0 0 12px;
        color: white;
        font-weight: 600;
        font-size: 20px;
        font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
        vertical-align: middle;
    }
}

.sider {
    position: relative;
    z-index: 10;
    min-height: 100vh;
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);

    &.fixSiderBar {
        position: fixed;
        top: 0;
        left: 0;
        box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);

        :global {
            .ant-menu-root {
                overflow-y: auto;
            }

            .ant-menu-inline {
                border-right: 0;

                .ant-menu-item,
                .ant-menu-submenu-title {
                    width: 100%;
                }
            }
        }
    }

    &.light {
        background-color: white;
        box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);

        .logo {
            background: white;
            box-shadow: 1px 1px 0 0 #e8e8e8;

            h1 {
                color: #1890ff;
            }
        }

        :global(.ant-menu-light) {
            border-right-color: transparent;
        }
    }
}

.icon {
    width: 14px;
    vertical-align: baseline;
}

:global {
    .top-nav-menu li.ant-menu-item {}

    .drawer .drawer-content {
        background: #001529;
    }

    .ant-menu-inline-collapsed {

        &>.ant-menu-item .sider-menu-item-img+span,
        &>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .sider-menu-item-img+span,
        &>.ant-menu-submenu>.ant-menu-submenu-title .sider-menu-item-img+span {
            display: inline-block;
            max-width: 0;
            opacity: 0;
        }
    }

    .ant-menu-item .sider-menu-item-img+span,
    .ant-menu-submenu-title .sider-menu-item-img+span {
        opacity: 1;
    }

    .ant-drawer-left {
        .ant-drawer-body {
            padding: 0;
        }
    }
}