.radar {
    .legend {
        margin-top: 16px;
        .legendItem {
            position: relative;
            line-height: 22px;
            text-align: center;
            cursor: pointer;
            p {
                margin: 0;
            }
            h6 {
                margin-top: 4px;
                margin-bottom: 0;
                padding-left: 16px;
                font-size: 24px;
                line-height: 32px;
            }
            &::after {
                position: absolute;
                top: 8px;
                right: 0;
                width: 1px;
                height: 40px;
                content: '';
            }
        }
        > :last-child .legendItem::after {
            display: none;
        }
        .dot {
            position: relative;
            top: -1px;
            display: inline-block;
            width: 6px;
            height: 6px;
            margin-right: 6px;
            border-radius: 6px;
        }
    }
}
